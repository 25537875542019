import { Grid } from "@mui/material";
import { EnrichedActivity } from "getstream";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRef } from "react";

import OrientationBanner from "components/banners/OrientationBanner";
import MobileDownloadPopover from "components/banners/OrientationBanner/MobileDownloadPopover";
import ErrorBoundary from "components/errors/ErrorBoundary";
import useIdentity from "hooks/useIdentity";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { useOrientationTasks } from "hooks/useOrientationTasks";
import { useApp } from "lib/common/appProvider";
import {
  GENERAL_FEED_GROUP,
  TIMELINE_FEED_GROUP,
} from "lib/motorcade/constants";
import useFeed from "lib/motorcade/hooks/useFeed";
import { FeedActivity } from "lib/motorcade/types";

import ActivityEditorCTA from "./ActivityEditor/ActivityEditorCTA";
import EPCarouselBanner from "./banners/EPCarouselBanner";
import FeedCard from "./FeedCard";
import FeedLoading from "./FeedLoading";
import NewActivityIndicator from "./NewActivityIndicator";

export default function Feed() {
  const auth = useApp("auth");

  const userId = auth?.user?.uid;
  const { role, communityCode } = useIdentity();
  const { isDismissedBanner, isInOrientation, isStepCompleted } =
    useOrientationTasks();
  const { permCreateFeedPosts } = useFlags();

  const feedGroup = role === "ADMIN" ? GENERAL_FEED_GROUP : TIMELINE_FEED_GROUP;
  const feedId = role === "ADMIN" ? `${communityCode}_admin` : userId;
  const {
    activities,
    fetchNextPage,
    hasNext,
    isLoading,
    isFetching,
    newActivities,
    clearNewActivities,
  } = useFeed(feedGroup, feedId);

  const lastVisibleElement = useInfiniteScroll({
    hasNext,
    loading: isLoading,
    getNext: fetchNextPage,
  });

  const firstActivityTrackerRef = useRef(null);
  const showOrientationBanner = isInOrientation && !isDismissedBanner;

  const feedFilter = (aggregatedActivity) => {
    const { verb } = aggregatedActivity;

    if (["reply", "comment"].includes(verb)) return false;

    if (verb === "follow") {
      const isBeingFollowed = aggregatedActivity?.activities?.some(
        (activity) => activity.object.id === userId
      );

      if (isBeingFollowed) return false;
    }

    if (["community_expertpanel_answer", "follow", "join"].includes(verb)) {
      const dedupeActors = new Set(
        aggregatedActivity?.activities?.map((activity) => activity.actor.id)
      );

      const isOnlyActor = dedupeActors.size === 1 && dedupeActors.has(userId);

      if (isOnlyActor) return false;
    }

    return true;
  };

  const filteredActivities = (activities?.filter(feedFilter) ||
    []) as EnrichedActivity[];

  const showMobileDownloadPopover =
    isInOrientation && !isStepCompleted("download");

  if (isLoading) {
    return <FeedLoading />;
  }

  return (
    <ErrorBoundary>
      {showOrientationBanner ? <OrientationBanner /> : <EPCarouselBanner />}

      {permCreateFeedPosts && <ActivityEditorCTA />}

      <Grid container position="relative" spacing={2}>
        <NewActivityIndicator
          key={
            filteredActivities?.[0]?.activities?.[0]?.id ??
            filteredActivities?.[0]?.id
          }
          clearNewActivities={clearNewActivities}
          newActivities={newActivities}
          trackerRef={firstActivityTrackerRef}
        />

        <div ref={firstActivityTrackerRef} />

        {filteredActivities.map((activity: FeedActivity) => {
          return (
            <Grid key={activity?.id} item xs={12}>
              <FeedCard activity={activity} />
            </Grid>
          );
        })}
      </Grid>
      {isFetching && <FeedLoading />}
      <div ref={lastVisibleElement} />
      {showMobileDownloadPopover && <MobileDownloadPopover />}
    </ErrorBoundary>
  );
}
